import {
  Button,
  ButtonVariant,
  DataTable,
  IMenuItem,
  Input,
  Link,
  LoadingSpinner,
  SearchSelect,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  PaidLeaveRequestStatus,
  PaidLeaveRequestWithWorkerShifts,
} from '@traba/types'
import { getDateRangeStringForDates } from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import { Col, Row } from 'src/components/base'
import Pagination from 'src/components/base/Pagination/Pagination'
import { DataTableHeader } from 'src/components/base/Table/DataTable'
import { useBasicPagination } from 'src/hooks/usePagination'
import { usePaidLeaveRequests } from 'src/hooks/usePaidLeaveRequest'
import { WorkerSickLeaveReviewModal } from './WorkerSickLeaveReviewModal'

const DEFAULT_PAGE_SIZE = 200

export const WorkerSickLeave = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const [workerId, setWorkerId] = useState<string | undefined>(
    searchParams.get('workerId') ?? undefined,
  )
  const statusOptions = Object.values(PaidLeaveRequestStatus).map((value) => ({
    value: value,
    label: value,
  }))
  const [selectedStatus, setSelectedStatus] = useState<
    IMenuItem<PaidLeaveRequestStatus> | undefined
  >({
    value: PaidLeaveRequestStatus.PENDING,
    label: PaidLeaveRequestStatus.PENDING,
  })
  const [selectedPaidLeaveRequest, setSelectedPaidLeaveRequest] = useState<
    PaidLeaveRequestWithWorkerShifts | undefined
  >(undefined)
  const { currentPage, goToNextPage, goToPreviousPage } = useBasicPagination()

  const { paidLeaveRequests, isFetching, refetch } = usePaidLeaveRequests({
    page: currentPage,
    workerId: workerId,
    status: selectedStatus?.value,
  })

  const onClose = useCallback(() => {
    setSelectedPaidLeaveRequest(undefined)
    refetch()
  }, [refetch])

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'workerName',
      label: 'Worker name',
    },
    {
      key: 'companyName',
      label: 'Company Name',
    },
    {
      key: 'requestDates',
      label: 'Request Dates',
    },
    {
      key: 'numOfShifts',
      label: '# of Shifts',
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ]

  const tableRows: TableRow[] = useMemo(() => {
    return (
      paidLeaveRequests?.map((paidLeaveRequest) => {
        const { worker, workerShifts, workerId, id } = paidLeaveRequest
        return {
          key: id,
          cells: [
            {
              key: `${id}-name`,
              renderFn: () => {
                const fullName = `${worker.firstName} ${worker.lastName}`
                const truncatedName =
                  fullName.length > 24
                    ? fullName.slice(0, 21) + '...'
                    : fullName
                return (
                  <Link to={`/workers/${workerId}`} target="_blank">
                    {truncatedName}
                  </Link>
                )
              },
            },
            {
              key: `${id}-company-name`,

              renderFn: () => {
                return (
                  <Row>
                    <Text>
                      {workerShifts.map((shift) => shift.employerName)}
                    </Text>
                  </Row>
                )
              },
            },
            {
              key: `${id}-request-dates`,
              renderFn: () => {
                return (
                  <Col>
                    {getDateRangeStringForDates(
                      workerShifts.map((shift) => shift.startTime),
                      workerShifts[0].timezone,
                    )}
                  </Col>
                )
              },
            },
            {
              key: `${id}-num-of-shifts`,

              renderFn: () => {
                return <Text>{workerShifts.length}</Text>
              },
            },
            {
              key: `${id}-phone-number`,
              content: worker.phoneNumber,
              renderFn: () => {
                return <Text>{worker.phoneNumber}</Text>
              },
            },
            {
              key: `${id}-actions`,
              renderFn: () => (
                <Button
                  slim
                  variant={
                    paidLeaveRequest.status === PaidLeaveRequestStatus.PENDING
                      ? ButtonVariant.FILLED
                      : ButtonVariant.TEXT
                  }
                  onClick={() => {
                    setSelectedPaidLeaveRequest(paidLeaveRequest)
                  }}
                >
                  {paidLeaveRequest.status === PaidLeaveRequestStatus.PENDING
                    ? 'Review'
                    : 'View details'}
                </Button>
              ),
            },
          ],
        }
      }) || []
    )
  }, [paidLeaveRequests])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row gap={theme.space.xxs} mb={theme.space.xs} alignCenter justifyBetween>
        <Row gap={theme.space.xxs} fullWidth>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="Worker ID"
            value={workerId}
            onChange={(e) => setWorkerId(e.target.value)}
            onClear={() => setWorkerId('')}
          />
          <SearchSelect
            options={statusOptions}
            selectItem={selectedStatus}
            handleSelect={(value) => {
              if (value) {
                setSelectedStatus({
                  value: value.value as PaidLeaveRequestStatus,
                  label: value.label as PaidLeaveRequestStatus,
                })
              } else {
                setSelectedStatus(undefined)
              }
            }}
            label={`Status`}
            style={{ width: '30%' }}
          />
        </Row>
        <Row
          gap={theme.space.xxs}
          style={{
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Pagination
            dataSize={paidLeaveRequests.length}
            onPageLeft={goToPreviousPage}
            onPageRight={goToNextPage}
            page={currentPage}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </Row>
      </Row>

      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={0}
        />
      )}
      {selectedPaidLeaveRequest && (
        <WorkerSickLeaveReviewModal
          isOpen={!!selectedPaidLeaveRequest}
          onClose={onClose}
          paidLeaveRequest={selectedPaidLeaveRequest}
        />
      )}
    </Col>
  )
}
