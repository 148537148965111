import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Col,
  DataTable,
  DataTableHeader,
  InlineBanner,
  Input,
  LinkText,
  LoadingSpinner,
  Row,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme, Z_INDEXES } from '@traba/theme'
import {
  PaidLeaveRequestStatus,
  PaidLeaveRequestWithWorkerShifts,
} from '@traba/types'
import { formatShiftTime } from '@traba/utils'
import { differenceInMinutes } from 'date-fns'
import { sum } from 'lodash'
import { Fragment, useMemo, useState } from 'react'
import { Modal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { updatePaidLeaveRequest } from 'src/hooks/usePaidLeaveRequest'
import { useWorkerBenefits } from 'src/hooks/useWorkerBenefits'
import { calculatePaidShiftTime } from 'src/utils/moneyUtils'
import { getPayRateString } from 'src/utils/stringUtils'
import { AvailableBalanceInJurisdiction } from './AvailableBalanecInJurisdiction'

interface Props {
  isOpen: boolean
  onClose: () => void
  paidLeaveRequest: PaidLeaveRequestWithWorkerShifts
}

export const WorkerSickLeaveReviewModal = (props: Props) => {
  const { isOpen, onClose, paidLeaveRequest } = props
  const { worker, workerShifts } = paidLeaveRequest
  const { workerBenefits, isLoadingBenefits } = useWorkerBenefits(
    worker.workerId,
  )
  const { showError, showSuccess } = useAlert()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [opsNotes, setOpsNotes] = useState('')

  const canContinue = useMemo(() => {
    return !!opsNotes
  }, [opsNotes])

  const showActionButtons = useMemo(() => {
    return paidLeaveRequest.status === PaidLeaveRequestStatus.PENDING
  }, [paidLeaveRequest.status])

  const onDeny = async () => {
    try {
      setIsSubmitting(true)
      await updatePaidLeaveRequest({
        paidLeaveRequestId: paidLeaveRequest.id,
        status: PaidLeaveRequestStatus.DENIED,
        opsNotes,
      })
      setIsSubmitting(false)
      showSuccess('Sick leave request denied')
      onClose()
    } catch (error) {
      showError('Failed to deny sick leave request')
    }
  }

  const onApprove = async () => {
    try {
      setIsSubmitting(true)
      await updatePaidLeaveRequest({
        paidLeaveRequestId: paidLeaveRequest.id,
        status: PaidLeaveRequestStatus.APPROVED,
        opsNotes,
      })
      setIsSubmitting(false)
      showSuccess('Sick leave request approved')
      onClose()
    } catch (error) {
      showError('Failed to approve sick leave request')
    }
  }

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'shiftTime',
      label: 'Shift Time',
    },
    {
      key: 'shiftRole',
      label: 'Shift Role',
    },
    {
      key: 'companyName',
      label: 'Company Name',
    },
    {
      key: 'payRate',
      label: 'Pay Rate',
    },
    {
      key: 'shiftHours',
      label: 'Shift Hours',
    },
    {
      key: 'workerShiftStatus',
      label: 'Worker Shift Status',
    },
  ]

  const tableRowData = useMemo(() => {
    return workerShifts.map((workerShift) => {
      const {
        startTime,
        endTime,
        shiftRole,
        employerName,
        payRate,
        payType,
        scheduledBreaks,
        id,
        timezone,
        shiftId,
      } = workerShift
      const totalBreakTime = scheduledBreaks.reduce(
        (acc, breakTime) => acc + breakTime.breakLength,
        0,
      )
      return {
        id,
        shiftTime: formatShiftTime(startTime, endTime, timezone),
        shiftRole,
        shiftId,
        companyName: employerName,
        payRate: getPayRateString(payType, payRate),
        shiftHours:
          calculatePaidShiftTime(
            totalBreakTime,
            differenceInMinutes(endTime, startTime),
            workerShift.breakType,
          ) / 60,
        workerShiftStatus: workerShift.jobStatus,
      }
    })
  }, [workerShifts])

  const tableRows: TableRow[] = useMemo(() => {
    return (
      tableRowData.map((row) => {
        const {
          shiftHours,
          shiftRole,
          companyName,
          payRate,
          workerShiftStatus,
          shiftTime,
          shiftId,
          id,
        } = row

        return {
          key: id,
          cells: [
            {
              key: `${id}-shift-time`,
              renderFn: () => <Text variant="body2">{shiftTime}</Text>,
            },
            {
              key: `${id}-shift-role`,
              renderFn: () => (
                <LinkText
                  variant="link"
                  onClick={() => {
                    window.open(`/field-monitor/${shiftId}`)
                  }}
                >
                  {shiftRole}
                </LinkText>
              ),
            },
            {
              key: `${id}-company-name`,
              renderFn: () => <Text>{companyName}</Text>,
            },
            {
              key: `${id}-pay-rate`,
              renderFn: () => <Text>{payRate}</Text>,
            },
            {
              key: `${id}-shift-hours`,
              renderFn: () => {
                return <Text>{shiftHours}</Text>
              },
            },
            {
              key: `${id}-worker-shift-status`,
              renderFn: () => {
                return <Text>{workerShiftStatus}</Text>
              },
            },
          ],
        }
      }) || []
    )
  }, [tableRowData])

  const modalTitle = useMemo(() => {
    if (paidLeaveRequest.status === PaidLeaveRequestStatus.PENDING) {
      return `Review sick leave request for ${worker.firstName} ${worker.lastName}`
    }
    return `Sick leave request for ${worker.firstName} ${worker.lastName} - ${paidLeaveRequest.status}`
  }, [worker, paidLeaveRequest.status])

  if (isLoadingBenefits) {
    return <LoadingSpinner />
  }

  return (
    <Modal
      handleClose={onClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={modalTitle}
      style={{
        zIndex: Z_INDEXES.MODAL,
      }}
    >
      <Col style={{ flex: 1 }}>
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={0}
        />

        <LinkText
          variant="link"
          onClick={() => window.open(`/worker/${worker.workerId}`)}
        >
          Go to worker page
        </LinkText>

        {showActionButtons && (
          <Input
            label="Ops notes (Required)"
            value={opsNotes}
            onChange={(e) => {
              setOpsNotes(e.target.value)
            }}
            containerStyle={{ width: 250, marginRight: theme.space.xs }}
            placeholder="Write down any notes"
          />
        )}
      </Col>

      {showActionButtons && (
        <Fragment>
          <Col style={{ alignItems: 'flex-end' }}>
            {workerBenefits?.jurisdictionBenefits.map((benefit) => (
              <AvailableBalanceInJurisdiction
                key={benefit.jurisdictionId}
                benefit={benefit}
              />
            ))}
            <Text variant="h5">Total shift hours</Text>
            <Text variant="h3">
              {sum(tableRowData.map((data) => data.shiftHours))}
            </Text>
          </Col>
          <InlineBanner text="When approved, worker will get paid for the available balance. If their balance is insufficient, the remaining hours will be unpaid." />

          <Row
            fullWidth
            style={{
              marginTop: theme.space.med,
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant={ButtonVariant.OUTLINED}
              disabled={!canContinue}
              loading={isSubmitting}
              onClick={() => {
                setIsSubmitting(true)
                onDeny()
              }}
            >
              Deny
            </Button>
            <Button
              onClick={async () => {
                try {
                  setIsSubmitting(true)
                  onApprove()
                } finally {
                  setIsSubmitting(false)
                }
              }}
              disabled={!canContinue}
              loading={isSubmitting}
            >
              Approve
            </Button>
          </Row>
        </Fragment>
      )}
    </Modal>
  )
}
