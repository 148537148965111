import { Col, Text } from '@traba/react-components'
import { JurisdictionBenefits } from '@traba/types'
import { millisecondsToHoursWithTwoDecimals } from '@traba/utils'

interface Props {
  benefit: JurisdictionBenefits
}

export const AvailableBalanceInJurisdiction = (props: Props) => {
  const { benefit } = props
  return (
    <Col style={{ alignItems: 'flex-end' }}>
      <Text variant="h5">Available Balance in {benefit.jurisdictionState}</Text>
      <Text variant="h3">
        {millisecondsToHoursWithTwoDecimals(
          benefit.sickPaidLeave.runningTotalAvailableBalanceMillis,
        )}
      </Text>
    </Col>
  )
}
