import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { WorkerBenefitsResponse } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

const getWorkerBenefits = async (workerId: string) => {
  try {
    const { data } = await trabaApi.get(`/workers-w2/benefits/${workerId}`)
    return data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useWorkerBenefits = (workerId: string) => {
  const {
    data: workerBenefits,
    isLoading: isLoadingBenefits,
    refetch: refetchBenefits,
  } = useQuery<WorkerBenefitsResponse>({
    queryKey: ['worker-benefits', workerId],
    queryFn: () => getWorkerBenefits(workerId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    workerBenefits,
    isLoadingBenefits,
    refetchBenefits,
  }
}
