import { BreakType } from './companies'
import { ScheduledBreak, ShiftPayType } from './shifts'
import { JobStatus } from './worker-shift'

export enum PaidLeaveType {
  SICK_TIME = 'SICK_TIME',
}

export enum PaidLeaveRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  CANCELED = 'CANCELED',
}

export type PaidLeaveRequestWithWorkerShifts = {
  id: string
  createdAt: Date
  updatedAt: Date
  workerId: string
  type: PaidLeaveType
  status: PaidLeaveRequestStatus
  reviewedAt?: Date
  opsReviewer?: string
  workerNotes?: string
  opsNotes?: string
  workerShifts: PaidLeaveRequestWorkerShift[]
  worker: {
    workerId: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }
}

export type PaidLeaveRequestWorkerShift = {
  employerName: string
  startTime: Date
  endTime: Date
  shiftRole: string
  shortLocation: string | null
  locationId: string
  payRate: number
  payType: ShiftPayType
  timezone: string
  id: string
  scheduledBreaks: ScheduledBreak[]
  breakType: BreakType
  jobStatus: JobStatus
  shiftId: string
}
