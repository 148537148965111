import { Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { JurisdictionBenefits } from '@traba/types'
import { millisecondsToHoursWithTwoDecimals } from '@traba/utils'

interface Props {
  jurisdiction: JurisdictionBenefits
}

export const WorkerBenefitsStateCard = ({ jurisdiction }: Props) => {
  const { sickPaidLeave } = jurisdiction
  return (
    <Col>
      <Text variant="h5" color={theme.colors.brand}>
        State: {jurisdiction.jurisdictionState}
      </Text>
      <Row
        alignCenter
        style={{
          border: `1px solid ${theme.colors.Grey30}`,
          borderRadius: theme.space.xs,
          padding: theme.space.sm,
        }}
      >
        <Col
          style={{
            borderRight: `1px solid ${theme.colors.Grey30}`,
            paddingRight: theme.space.sm,
          }}
        >
          <Text>Total hours accrued</Text>
          <Text variant="h5">
            {millisecondsToHoursWithTwoDecimals(
              sickPaidLeave.runningTotalAccruedMillis,
            )}{' '}
            hours
          </Text>
        </Col>

        <Col
          style={{
            borderRight: `1px solid ${theme.colors.Grey30}`,
            paddingLeft: theme.space.sm,
            paddingRight: theme.space.sm,
          }}
        >
          <Text>Total hours upcoming requested</Text>
          <Text variant="h5">
            {millisecondsToHoursWithTwoDecimals(
              sickPaidLeave.runningTotalUpcomingRequestedMillis,
            )}{' '}
            hours
          </Text>
        </Col>
        <Col
          style={{
            borderRight: `1px solid ${theme.colors.Grey30}`,
            paddingLeft: theme.space.sm,
            paddingRight: theme.space.sm,
          }}
        >
          <Text>Total hours available balance</Text>
          <Text variant="h5">
            {millisecondsToHoursWithTwoDecimals(
              sickPaidLeave.runningTotalAvailableBalanceMillis,
            )}{' '}
            hours
          </Text>
        </Col>
        <Col
          style={{
            paddingLeft: theme.space.sm,
            paddingRight: theme.space.sm,
          }}
        >
          <Text>Total hours used</Text>
          <Text variant="h5">
            {millisecondsToHoursWithTwoDecimals(
              sickPaidLeave.runningTotalUsedMillis,
            )}{' '}
            hours
          </Text>
        </Col>
      </Row>
    </Col>
  )
}
