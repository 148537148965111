import { Col, LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import Divider from 'src/components/base/Divider'
import { usePaidLeaveRequests } from 'src/hooks/usePaidLeaveRequest'
import { useWorkerBenefits } from 'src/hooks/useWorkerBenefits'
import { WorkerBenefitsRequestCard } from './WorkerBenefitsRequestCard'
import { WorkerBenefitsStateCard } from './WorkerBenefitsStateCard'

interface Props {
  workerId: string
}

export const WorkerBenefitsTab = ({ workerId }: Props) => {
  const { workerBenefits, isLoadingBenefits } = useWorkerBenefits(workerId)
  const { jurisdictionBenefits } = workerBenefits ?? {}
  const { paidLeaveRequests, isLoading: isLoadingPaidLeaveRequests } =
    usePaidLeaveRequests({
      page: 0,
      pageSize: 500,
      workerId,
    })
  if (isLoadingBenefits || isLoadingPaidLeaveRequests) {
    return <LoadingSpinner />
  }

  if (!jurisdictionBenefits || jurisdictionBenefits?.length === 0) {
    return <Text>No benefits found for this worker</Text>
  }

  return (
    <Col>
      <Text variant="h5" mb={theme.space.sm}>
        Paid sick leave balances
      </Text>
      {jurisdictionBenefits.map((jurisdiction) => (
        <WorkerBenefitsStateCard
          key={jurisdiction.jurisdictionId}
          jurisdiction={jurisdiction}
        />
      ))}
      <Divider />

      <Col gap={theme.space.xs}>
        <Text variant="h5" mt={theme.space.sm}>
          Paid sick leave requests
        </Text>
        {paidLeaveRequests.map((request) => (
          <WorkerBenefitsRequestCard key={request.id} request={request} />
        ))}
      </Col>
    </Col>
  )
}
