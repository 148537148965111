import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Card,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  PaidLeaveRequestStatus,
  PaidLeaveRequestWithWorkerShifts,
} from '@traba/types'
import { getDateRangeStringForDates } from '@traba/utils'
import { useMemo, useState } from 'react'
import { WorkerSickLeaveReviewModal } from 'src/screens/ActionCenter/components/WorkerSickLeave/WorkerSickLeaveReviewModal'

interface Props {
  request: PaidLeaveRequestWithWorkerShifts
}

export const WorkerBenefitsRequestCard = ({ request }: Props) => {
  const { workerShifts } = request
  const [isModalOpen, setIsModalOpen] = useState(false)

  const textDetails = useMemo(() => {
    switch (request.status) {
      case PaidLeaveRequestStatus.PENDING:
        return {
          badgeVariant: BadgeVariant.YELLOW,
          buttonTitle: 'Review',
          buttonVariant: ButtonVariant.BRANDNEW,
        }
      case PaidLeaveRequestStatus.APPROVED:
        return {
          badgeVariant: BadgeVariant.BRAND,
          buttonTitle: 'View details',
          buttonVariant: ButtonVariant.LINK,
        }
      case PaidLeaveRequestStatus.DENIED:
        return {
          badgeVariant: BadgeVariant.DANGER,
          buttonTitle: 'View details',
          buttonVariant: ButtonVariant.LINK,
        }
      case PaidLeaveRequestStatus.CANCELED:
        return {
          badgeVariant: BadgeVariant.GREY,
          buttonTitle: 'View details',
          buttonVariant: ButtonVariant.LINK,
        }
    }
  }, [request.status])

  return (
    <Card>
      <Row alignCenter gap={theme.space.xs} justifyBetween>
        <Row gap={theme.space.xs}>
          <Text variant="h6" color={theme.colors.brand}>
            {workerShifts.map((ws) => ws.employerName).join(', ')}
          </Text>
          <Text>
            {getDateRangeStringForDates(
              workerShifts.map((shift) => shift.startTime),
              workerShifts[0].timezone,
            )}
          </Text>

          <Badge variant={textDetails.badgeVariant} title={request.status} />
        </Row>
        <Button
          variant={textDetails.buttonVariant}
          onClick={() => setIsModalOpen(true)}
        >
          {textDetails.buttonTitle}
        </Button>
      </Row>
      <WorkerSickLeaveReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        paidLeaveRequest={request}
      />
    </Card>
  )
}
