import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  PaginatedResponse,
  PaidLeaveRequestStatus,
  PaidLeaveRequestWithWorkerShifts,
  PaidLeaveType,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'

export async function updatePaidLeaveRequest({
  paidLeaveRequestId,
  status,
  opsNotes,
}: {
  paidLeaveRequestId: string
  status: PaidLeaveRequestStatus
  opsNotes?: string
}) {
  try {
    const response = await trabaApi.patch(
      `/paid-leave-request/${paidLeaveRequestId}/update`,
      {
        status,
        opsNotes,
      },
    )
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function getPaidLeaveRequests({
  page,
  pageSize,
  workerId,
  status,
}: {
  page: number
  pageSize?: number
  workerId?: string
  status?: PaidLeaveRequestStatus
}) {
  try {
    const payload = {
      page,
      query: {
        workerId,
        type: PaidLeaveType.SICK_TIME,
        status,
      },
      pageSize,
    }
    const response = await trabaApi.post(`/paid-leave-request/query`, payload)
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const usePaidLeaveRequests = ({
  page,
  pageSize,
  workerId,
  status,
}: {
  page: number
  pageSize?: number
  workerId?: string
  status?: PaidLeaveRequestStatus
}) => {
  const { showError } = useAlert()
  const { data, isFetching, isError, error, refetch, isLoading } = useQuery<
    PaginatedResponse<PaidLeaveRequestWithWorkerShifts>,
    AxiosError
  >({
    queryKey: ['paid-leave-requests', page, workerId, status],
    queryFn: () =>
      getPaidLeaveRequests({
        page: page + 1,
        workerId,
        status,
        pageSize,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  if (isError) {
    showError(error.message, 'Failed to load paid leave requests')
  }

  return {
    isFetching,
    isError,
    paidLeaveRequests: data?.items || [],
    error,
    refetch,
    isLoading,
  }
}
